// english words
const englishWords: string[] = [
    "dashboard",
    "product",
    "new product",
    "stock taking",
    "adjust stock",
    "list products",
    "list adjustments",
    "sale",
    "new sale",
    "new order",
    "list sales",
    "list orders",
    "new proforma invoice",
    "list proforma invoice",
    "expense",
    "new expense",
    "list expenses",
    "new expense type",
    "list expense types",
    "purchase",
    "new purchase",
    "list purchases",
    "customer",
    "new customer",
    "list customers",
    "debt",
    "new debt",
    "list debts",
    "list debt history",
    "message",
    "new message",
    "list messages",
    "report",
    "new report",
    "statistics",
    "general report",
    "user",
    "new user",
    "list users",
    "role",
    "new role",
    "list roles",
    "payment",
    "branch",
    "new branch",
    "list branches",
    "list activities",
    "store",
    "new store",
    "list stores",
    "new store product",
    "list store products",
    "profile",
    "view profile",
    "change password",
    "login",
    "logout",
    "page not found",
    "oops! page not found",
    "sorry, but the page you are looking for is not found. please, make sure you have type the correct url and your have permission to view the resource.",
    "create",
    "required",
    "name",
    "enter name",
    "buying price",
    "enter buying price",
    "selling price",
    "enter selling price",
    "stock available",
    "enter stock available",
    "re-order stock level",
    "enter re-order stock level (stock alert)",
    "upload or import from excel",
    "import products from excel",
    "choose file",
    "no file chosen",
    "file is required or file has no product(s)",
    "download sample product template",
    "close",
    "import",
    "list almost out of stock",
    "list deleted",
    "list in stock",
    "list out of stock",
    "ascending order",
    "descending order",
    "created time",
    "stock",
    "limit of",
    "limit by",
    "enter search keyword",
    "sold",
    "status",
    "options",
    "available",
    "edit",
    "view",
    "edit product",
    "update",
    "product information",
    "total sold",
    "reorder stock level",
    "created by",
    "created moment",
    "updated by",
    "updated moment",
    "delete",
    "deleted",
    "deleted by",
    "deleted date",
    "deleted product",
    "restore",
    "updated successfully",
    "adjust",
    "stock adjustment",
    "enter product",
    "available stock",
    "type",
    "stock to adjust",
    "enter stock to adjust",
    "stock after adjustment",
    "stock adjustments",
    "cash",
    "credit",
    "S/A",
    "product does not exist",
    "quantity",
    "enter quantity",
    "customer (optional)",
    "enter customer",
    "customer name is required ",
    "customer does not exist ",
    "error",
    "validating",
    "created successfully",
    "cart",
    "product has been removed from cart",
    "amount",
    "discount",
    "remove",
    "total",
    "print",
    "save",
    "can't be greater than selling price",
    "out of stock",
    "almost out of stock",
    "in stock",
    "almost out",
    "data already exist",
    "list",
    "product is required",
    "increase",
    "decrease",
    "stock after adjustment can't be less than 0",
    "before adjustment",
    "adjustment",
    "after adjustment",
    "moment",
    "increased",
    "decreased",
    "adjustment information",
    "no enough stock",
    "profit",
    "loss",
    "sale information",
    "extra profit",
    "no sale has been found",
    "sale confirmation",
    "are you sure you want to delete this sale?",
    "are you sure you want to delete these sales?",
    "are you sure you want to restore this sale?",
    "are you sure you want to restore these sales?",
    "decline",
    "accept",
    "list order",
    "no order has been found",
    "orders",
    "number",
    "products",
    "list today orders",
    "view order",
    "order information",
    "new invoice",
    "list invoice",
    "list today proforma invoice",
    "view proforma invoice",
    "proforma invoice information",
    "sale has been deleted",
    "order has been deleted",
    "order confirmation",
    "are you sure you want to delete this order?",
    "are you sure you want to delete these orders?",
    "are you sure you want to restore this order?",
    "are you sure you want to restore these orders?",
    "failed to update sale",
    "failed to update order",
    "proforma invoice",
    "no expense type has been found",
    "select type",
    "date",
    "total amount",
    "enter total amount",
    "paid amount",
    "enter paid amount",
    "description",
    "enter description",
    "can't be greater than total amount",
    "can't be less or equal to zero",
    "expense has been deleted",
    "no expense has been found",
    "expenses",
    "list of today",
    "list paid",
    "list unpaid",
    "remain amount",
    "expense confirmation",
    "are you sure you want to delete this expense?",
    "are you sure you want to delete these expenses?",
    "are you sure you want to restore this expense?",
    "are you sure you want to restore these expenses?",
    "edit expense",
    "expense information",
    "view expense",
    "expense has been restored",
    "invalid date",
    "expense type confirmation",
    "are you sure you want to delete this expense type?",
    "are you sure you want to delete these expense types?",
    "are you sure you want to restore this expense type?",
    "are you sure you want to restore these expense types?",
    "expense type information",
    "view expense type",
    "expense types",
    "edit expense type",
    "expense type has been deleted",
    "failed to update all",
    "expense type has been restored",
    "active",
    "expenses have been restored",
    "expenses have been deleted",
    "expense types have been restored",
    "expense types have been deleted",
    "purchased stock",
    "enter stock purchased",
    "can't be less than buying price",
    "product re-order stock level",
    "failed to fetch",
    "paid",
    "purchases",
    "purchase confirmation",
    "are you sure you want to delete this purchase?",
    "are you sure you want to delete these purchases?",
    "are you sure you want to restore this purchase?",
    "are you sure you want to restore these purchases?",
    "purchase information",
    "purchase has been deleted",
    "view purchase",
    "edit purchase",
    "purchases have been deleted",
    "phone number",
    "enter phone number",
    "region",
    "select region",
    "location",
    "enter location",
    "street",
    "enter street",
    "tin number",
    "enter tin number",
    "import customers from excel",
    "download sample customer template",
    "customers",
    "list with tin number",
    "list without tin number",
    "export",
    "customer confirmation",
    "are you sure you want to delete this customer?",
    "are you sure you want to delete these customers?",
    "are you sure you want to restore this customer?",
    "are you sure you want to restore these customers?",
    "customer has been deleted",
    "customers have been deleted",
    "customer has been restored",
    "customers have been restored",
    "failed to update customer",
    "view customer",
    "edit customer",
    "customer information",
    "customer is owed",
    "shop is owed",
    "debt has been created",
    "debts",
    "list customer debts",
    "list sale debts",
    "list shop debts",
    "unpaid",
    "edit debt",
    "debt has been updated",
    "debt confirmation",
    "are you sure you want to delete this debt?",
    "are you sure you want to delete these debts?",
    "are you sure you want to restore this debt?",
    "are you sure you want to restore these debts?",
    "debt has been deleted",
    "debts have been deleted",
    "debt has been restored",
    "debts have been restored",
    "no debt has been found",
    "debt information",
    "debt payment",
    "debt view",
    "make payment",
    "amount paid",
    "enter amount paid",
    "amount paid is greater than remain amount",
    "sale debt",
    "no debt history has been found",
    "debt histories",
    "list debt histories",
    "debt history confirmation",
    "are you sure you want to delete this debt history?",
    "are you sure you want to delete this debt histories?",
    "debt history has been deleted",
    "debt histories have been deleted",
    "receivers",
    "select receiver type",
    "balance",
    "enter message",
    "select atleast 1 receiver",
    "report statistics",
    "system users",
    "cost",
    "send message",
    "message has been sent",
    "phone number must have 10 digits",
    "messages",
    "adjustments",
    "payments",
    "sales",
    "start date (from)",
    "end date (to)",
    "branch (optional)",
    "user (optional)",
    "deleted report",
    "deleted data report",
    "enter user",
    "enter branch",
    "adjustments report",
    "debt histories report",
    "debts report",
    "expenses report",
    "orders report",
    "payments report",
    "purchases report",
    "sales report",
    "adjustment type (optional)",
    "select adjustment type",
    "product (optional)",
    "store product (optional)",
    "enter store product",
    "increased adjustments",
    "decreased adjustments",
    "no adjustment has been found",
    "store product does not exist",
    "print report",
    "deleted adjustments report",
    "deleted debt histories report",
    "deleted debts report",
    "deleted expenses report",
    "deleted orders report",
    "deleted payments report",
    "deleted purchases report",
    "deleted sales report",
    "status (optional)",
    "select status",
    "expense type (optional)",
    "enter expense type",
    "no payment has been found",
    "payment type",
    "select payment type",
    "monthly subscription",
    "other",
    "sms purchase",
    "system installation",
    "sale status (optional)",
    "select sale status",
    "cash sales",
    "credit sales",
    "no debit history has been found",
    "no purchase has been found",
    "report type",
    "select report type",
    "enter branch name",
    "enter user name",
    "debt histories statistics",
    "debts statistics",
    "expenses statistics",
    "payments statistics",
    "purchases statistics",
    "sales statistics",
    "enter expense type name",
    "enter product name",
    "enter customer name",
    "new general report",
    "item",
    "income",
    "expenditure",
    "customer debts",
    "shop debts",
    "select branch",
    "select role",
    "account type",
    "select account type",
    "branch owner",
    "branch employee",
    "username",
    "enter username",
    "two factor authentication disabled",
    "two factor authentication enabled",
    "change branch settings",
    "file has been uploaded",
    "no branch has been found",
    "users",
    "list assistance",
    "list employee",
    "list not verified",
    "list owners",
    "list verified",
    "list with 2fa",
    "list without 2fa",
    "verified",
    "secured",
    "no",
    "yes",
    "phone number verified",
    "edit user",
    "2fa enabled",
    "user information",
    "user confirmation",
    "view user",
    "are you sure you want to delete this user?",
    "are you sure you want to restore this user?",
    "no user has been found",
    "user has been deleted",
    "user has been restored",
    "view branch",
    "edit branch",
    "list branch",
    "create branch",
    "delete branch",
    "switch branch",
    "list activity",
    "view branch menu",
    "list customer",
    "create customer",
    "delete customer",
    "view customer menu",
    "view dashboard",
    "view dashboard menu",
    "view debt",
    "list debt",
    "create debt",
    "delete debt",
    "view debt menu",
    "view debt history",
    "create debt payment",
    "delete debt history",
    "debt history",
    "list expense",
    "create expense",
    "delete expense",
    "view expense menu",
    "expense type",
    "list expense type",
    "create expense type",
    "delete expense type",
    "general",
    "restore deleted",
    "list message",
    "create message",
    "view message menu",
    "order",
    "create order",
    "delete order",
    "view payment",
    "list payment",
    "create payment",
    "cancel payment",
    "view payment menu",
    "view product",
    "list product",
    "create product",
    "delete product",
    "view product menu",
    "view buying price",
    "create proforma invoice",
    "delete proforma invoice",
    "list purchase",
    "create purchase",
    "delete purchase",
    "view purchase menu",
    "create report",
    "download report",
    "view report menu",
    "view general report",
    "view report statistics",
    "view deleted data report",
    "view role",
    "edit role",
    "list role",
    "create role",
    "delete role",
    "view role menu",
    "view sale",
    "list sale",
    "view loss",
    "create sale",
    "delete sale",
    "view profit",
    "view discount",
    "view sale menu",
    "view stock",
    "do stock taking",
    "list stock adjustment",
    "view stock adjsutment",
    "view store",
    "edit store",
    "list store",
    "create store",
    "delete store",
    "view store menu",
    "store product",
    "view store product",
    "edit store product",
    "list store product",
    "create store product",
    "delete store product",
    "list user",
    "create user",
    "delete user",
    "view user menu",
    "permissions",
    "roles",
    "role confirmation",
    "are you sure you want to delete this role?",
    "are you sure you want to delete these roles?",
    "are you sure you want to restore this role?",
    "are you sure you want to restore these roles?",
    "role has been deleted",
    "role has been restored",
    "select atleast 1 permission",
    "roles have been deleted",
    "roles have been restored",
    "role information",
    "list active",
    "list canceled",
    "list monthly subscription",
    "list other",
    "list payments",
    "list sms purchase",
    "list system installation",
    "list vendor name registration",
    "cancel",
    "payment information",
    "payment confirmation",
    "are you sure you want to cancel this payment?",
    "are you sure you want to cancel these payments?",
    "payment has been canceled",
    "payments have been canceled",
    "monthly subscription is required",
    "tin (optional)",
    "enter tin",
    "api key (optional)",
    "enter api key",
    "vendor (optional)",
    "enter vendor",
    "monthly fee (optional)",
    "enter fee",
    "logo",
    "branches",
    "days",
    "list with subscription",
    "list without subscription",
    "branch confirmation",
    "are you sure you want to delete this branch?",
    "are you sure you want to delete these branches?",
    "are you sure you want to restore this branch?",
    "are you sure you want to restore these branches?",
    "branch has been deleted",
    "branches have been deleted",
    "branch has been restored",
    "branches have been resored",
    "settings",
    "new payment",
    "branch information",
    "switch in this branch",
    "monthly fee",
    "remain days",
    "module",
    "modification",
    "creation",
    "deletion",
    "provider",
    "account name",
    "account number",
    "add new payment method",
    "new payment method",
    "select provider",
    "enter account name",
    "enter account number",
    "add method",
    "p.o box",
    "enter p.o box",
    "email",
    "enter email",
    "website",
    "enter website",
    "opening time",
    "closing time",
    "sale / order note on receipts",
    "enter sale or order note",
    "proforma invoice note",
    "enter proforma invoice note",
    "sms notifications",
    "customer sale or order receipt",
    "daily debts report",
    "daily report",
    "product stock",
    "remind customer debt",
    "store product stock",
    "branch data",
    "count",
    "activities",
    "store products",
    "stores",
    "list creation",
    "list deletion",
    "list modification",
    "activity",
    "continue",
    "edit profile",
    "old password",
    "enter old password",
    "old password must have atleast 8 characters",
    "new password must have atleast 8 characters",
    "password confirmation must have atleast 8 characters",
    "new password",
    "confirm new password",
    "password does not match",
    "enter new password",
    "old password is not correct",
    "welcome back!",
    "account",
    "enter username or phone number",
    "password",
    "enter password",
    "forgot password?",
    "account does not exist",
    "password is not correct",
    "password must have atleast 4 characters",
    "contact us",
    "already have an account",
    "send code",
    "failed to send verification code",
    "forgot password",
    "verification code has been sent to your phone number",
    "verification code",
    "code",
    "enter code sent to your phone number",
    "verify",
    "code must have 6 digits",
    "code is not correct",
    "data comparison",
    "summary",
    "a few seconds ago",
    "a minute ago",
    "2 minutes ago",
    "3 minutes ago",
    "4 minutes ago",
    "5 minutes ago",
    "6 minutes ago",
    "7 minutes ago",
    "8 minutes ago",
    "9 minutes ago",
    "10 minutes ago",
    "11 minutes ago",
    "12 minutes ago",
    "13 minutes ago",
    "14 minutes ago",
    "15 minutes ago",
    "16 minutes ago",
    "17 minutes ago",
    "18 minutes ago",
    "19 minutes ago",
    "20 minutes ago",
    "21 minutes ago",
    "22 minutes ago",
    "23 minutes ago",
    "24 minutes ago",
    "25 minutes ago",
    "26 minutes ago",
    "27 minutes ago",
    "28 minutes ago",
    "29 minutes ago",
    "30 minutes ago",
    "31 minutes ago",
    "32 minutes ago",
    "33 minutes ago",
    "34 minutes ago",
    "35 minutes ago",
    "36 minutes ago",
    "37 minutes ago",
    "38 minutes ago",
    "39 minutes ago",
    "40 minutes ago",
    "41 minutes ago",
    "42 minutes ago",
    "43 minutes ago",
    "44 minutes ago",
    "45 minutes ago",
    "46 minutes ago",
    "47 minutes ago",
    "48 minutes ago",
    "49 minutes ago",
    "50 minutes ago",
    "51 minutes ago",
    "52 minutes ago",
    "53 minutes ago",
    "54 minutes ago",
    "55 minutes ago",
    "56 minutes ago",
    "57 minutes ago",
    "58 minutes ago",
    "59 minutes ago",
    "an hour ago",
    "2 hours ago",
    "3 hours ago",
    "4 hours ago",
    "5 hours ago",
    "6 hours ago",
    "7 hours ago",
    "8 hours ago",
    "9 hours ago",
    "10 hours ago",
    "11 hours ago",
    "12 hours ago",
    "13 hours ago",
    "14 hours ago",
    "15 hours ago",
    "16 hours ago",
    "17 hours ago",
    "18 hours ago",
    "19 hours ago",
    "20 hours ago",
    "21 hours ago",
    "22 hours ago",
    "23 hours ago",
    "a day ago",
    "2 days ago",
    "3 days ago",
    "4 days ago",
    "5 days ago",
    "6 days ago",
    "7 days ago",
    "8 days ago",
    "9 days ago",
    "10 days ago",
    "11 days ago",
    "12 days ago",
    "13 days ago",
    "14 days ago",
    "15 days ago",
    "16 days ago",
    "17 days ago",
    "18 days ago",
    "19 days ago",
    "20 days ago",
    "21 days ago",
    "22 days ago",
    "23 days ago",
    "24 days ago",
    "25 days ago",
    "26 days ago",
    "27 days ago",
    "28 days ago",
    "29 days ago",
    "30 days ago",
    "a month ago",
    "2 months ago",
    "3 months ago",
    "4 months ago",
    "5 months ago",
    "6 months ago",
    "7 months ago",
    "8 months ago",
    "9 months ago",
    "10 months ago",
    "11 months ago",
    "12 months ago",
    "a year ago",
    "2 years ago",
    "3 years ago",
    "4 years ago",
    "5 years ago",
    "6 years ago",
    "7 years ago",
    "8 years ago",
    "9 years ago",
    "10 years ago",
    "data was created",
    "data was modified",
    "data was deleted temporary",
    "data Was deleted permanently",
    "true",
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "augost",
    "september",
    "october",
    "november",
    "december",
    "file is requied",
    "server payment",
    "store confirmation",
    "are you sure you want to delete this store?",
    "are you sure you want to delete these stores?",
    "are you sure you want to restore this store?",
    "are you sure you want to restore these stores?",
    "store has been deleted",
    "stores have been deleted",
    "store has been restored",
    "stores have been restored",
    "store information",
    "no store product has been found",
    "select store",
    "download sample store product template",
    "list decrease",
    "list increase",
    "no product has been found",
    "no store has been found",
    "no role has been found",
    "no customer has been found",
    "invalid api key",
    "tin must have 9 digits",
    "n/a",
    "payment methods",
    "english",
    "language",
    "system loading is enabled",
    "system loading is disabled",
    "sales profit",
    "helpers",
    "normal curreny",
    "formated currency",
    "extra formated currency",
    "unpaid expenses",
    "unpaid purchases",
    "swahili",
    "list stolen",
    "list stolen product",
    "debtor",
    "creditor",
]

export default englishWords